import React, { useState } from 'react';

const Calculator = () => {
  const [input, setInput] = useState('0');

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const buttonColors = {
    '7': 'bg-blue-500 hover:bg-blue-700',
    '8': 'bg-blue-500 hover:bg-blue-700',
    '9': 'bg-blue-500 hover:bg-blue-700',
    '/': 'bg-yellow-500 hover:bg-yellow-700',
    '4': 'bg-blue-500 hover:bg-blue-700',
    '5': 'bg-blue-500 hover:bg-blue-700',
    '6': 'bg-blue-500 hover:bg-blue-700',
    '*': 'bg-yellow-500 hover:bg-yellow-700',
    '1': 'bg-blue-500 hover:bg-blue-700',
    '2': 'bg-blue-500 hover:bg-blue-700',
    '3': 'bg-blue-500 hover:bg-blue-700',
    '-': 'bg-yellow-500 hover:bg-yellow-700',
    '0': 'bg-blue-500 hover:bg-blue-700',
    'C': 'bg-red-500 hover:bg-red-700',
    '=': 'bg-green-500 hover:bg-green-700',
    '+': 'bg-yellow-500 hover:bg-yellow-700',
  };

  const handleClick = (value) => {
    if (value === 'C') {
      setInput('0');
    } else if (value === '=') {
      try {
        setInput(eval(input).toString());
      } catch {
        setInput('Error');
      }
    } else {
      setInput(input === '0' ? value : input + value);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen pt-20">
      <div className="bg-gray-500 p-6 rounded-lg shadow-lg">
        <div className="mb-4 text-2xl text-right min-h-[50px] bg-white p-2 rounded">{formatNumberWithCommas(input)}</div>
        <div className="grid grid-cols-4 gap-2">
          {['7', '8', '9', '/',
            '4', '5', '6', '*',
            '1', '2', '3', '-',
            '0', 'C', '=', '+'].map((char) => (
            <button
              key={char}
              className={`text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${buttonColors[char]}`}
              onClick={() => handleClick(char)}
            >
              {char}
            </button>
          ))}
          {/* Added a new button that spans 4 columns */}
          <button
            className="text-white font-bold py-2 px-4 col-span-4 bg-purple-500 hover:bg-purple-700 rounded focus:outline-none focus:shadow-outline"
            onClick={() => handleClick('.')} // Functionality for the new button (you may need to adjust this)
          >
            .
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
