import React, { useState, useEffect, useRef, memo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import 'animate.css';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for unique ID generation

const ToDoList = () => {
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });
  const [newTask, setNewTask] = useState('');
  const [columnNames, setColumnNames] = useState({
    backlog: 'Backlog',
    inProgress: 'In Progress',
    completed: 'Completed',
  });
  const [editedColumnName, setEditedColumnName] = useState('');
  const [editingColumnId, setEditingColumnId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardDetails, setCardDetails] = useState(() => {
    const savedCardDetails = localStorage.getItem('cardDetails');
    return savedCardDetails ? JSON.parse(savedCardDetails) : {};
  });
  const [instances, setInstances] = useState(() => {
    const savedInstances = localStorage.getItem('instances');
    return savedInstances ? JSON.parse(savedInstances) : ['Default'];
  });
  const [selectedInstance, setSelectedInstance] = useState('Default');
  const [cardPriority, setCardPriority] = useState('Low');
  const detailsInputRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
    localStorage.setItem('cardDetails', JSON.stringify(cardDetails));
    localStorage.setItem('instances', JSON.stringify(instances));
  }, [tasks, cardDetails, instances]);

  const columns = {
    backlog: {
      name: columnNames.backlog,
      items: tasks.filter((task) => task.status === 'backlog' && task.instance === selectedInstance),
    },
    inProgress: {
      name: columnNames.inProgress,
      items: tasks.filter((task) => task.status === 'inProgress' && task.instance === selectedInstance),
    },
    completed: {
      name: columnNames.completed,
      items: tasks.filter((task) => task.status === 'completed' && task.instance === selectedInstance),
    },
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setTasks((prev) =>
        prev.map((task) =>
          task.id === removed.id
            ? { ...task, status: destination.droppableId, index: destItems.length - 1 }
            : task
        )
      );
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setTasks((prev) =>
        prev.map((task) => {
          if (task.status === source.droppableId && task.instance === selectedInstance) {
            if (task.id === removed.id) {
              return { ...task, index: destination.index };
            }
            if (task.index >= destination.index && task.index < source.index) {
              return { ...task, index: task.index + 1 };
            }
            if (task.index <= destination.index && task.index > source.index) {
              return { ...task, index: task.index - 1 };
            }
          }
          return task;
        })
      );
    }
  };

  const onDragUpdate = (update) => {
    const { source, destination, draggableId } = update;
    if (!destination) return;

    const newTasks = tasks.map((task) => {
      if (task.id === draggableId) {
        return { ...task, status: destination.droppableId };
      }
      return task;
    });
    setTasks(newTasks);
  };

  const addTask = () => {
    if (newTask.trim() !== '') {
      const newTaskObject = {
        id: uuidv4(), // Generate a unique ID for each task
        content: newTask,
        status: 'backlog',
        index: 0, // New task at the top
        details: '',
        priority: 'Low',
        instance: selectedInstance,
      };
      setTasks([newTaskObject, ...tasks]);
      setNewTask('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addTask();
    }
  };

  const handleColumnNameChange = (event) => {
    setEditedColumnName(event.target.value);
  };

  const handleColumnNameSave = (columnId) => {
    setColumnNames({
      ...columnNames,
      [columnId]: editedColumnName,
    });
    setEditingColumnId(null);
  };

  const handleColumnNameEdit = (columnId) => {
    setEditingColumnId(columnId);
    setEditedColumnName(columnNames[columnId]);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setCardPriority(card.priority || 'Low');
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [card.id]: prevDetails[card.id] || '',
    }));
  };

  const handleCardClose = () => {
    setSelectedCard(null);
  };

  const handleDeleteCard = (cardId) => {
    setTasks((prev) => prev.filter((task) => task.id !== cardId));
  };

  const handleOutsideClick = (event) => {
    if (event.target.classList.contains('modal-overlay')) {
      setSelectedCard(null);
    }
  };

  const handleDetailsChange = (event) => {
    const { value } = event.target;
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [selectedCard.id]: value,
    }));
  };

  const handleDetailsKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSelectedCard(null);
      detailsInputRef.current.blur();
    }
  };

  const handleDetailsBlur = () => {
    setSelectedCard(null);
    if (!cardDetails[selectedCard.id].trim()) {
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        [selectedCard.id]: '',
      }));
    }
  };

  const handleInstanceChange = (event) => {
    setSelectedInstance(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setCardPriority(event.target.value);
    setTasks((prev) =>
      prev.map((task) =>
        task.id === selectedCard.id ? { ...task, priority: event.target.value } : task
      )
    );
    detailsInputRef.current.focus(); // Deselect the dropdown and focus on details input
  };

  const MemoizedDraggableItem = memo(({ item, index, handleCardClick, handleDeleteCard, cardDetails }) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`p-4 mb-2 rounded shadow-md shadow-black relative transition-transform duration-300 ${
            snapshot.isDragging ? 'bg-gray-300' : 'bg-gray-200 hover:scale-105 cursor-pointer'
          }`}
          onClick={() => handleCardClick(item)}
          style={{
            zIndex: snapshot.isDragging ? 1000 : 'auto', // Ensure the dragged card is always on top
            ...provided.draggableProps.style,
          }}
        >
          <button
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteCard(item.id);
            }}
          >
            <FaTimes size={16} />
          </button>
          <div className="flex justify-between items-center">
            <div className="w-full">
              <p className="font-bold">{item.content}</p>
              {cardDetails[item.id] && (
                <div className="mt-2 text-sm bg-gray-300 rounded p-2 shadow-inner shadow-gray-400">
                  {cardDetails[item.id]}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <div className="min-h-screen bg-gray-700 p-8">
      <h1 className="text-2xl text-white font-bold mb-4 text-center">To Do List</h1>
      <div className="flex justify-center space-x-4 items-center mb-12">
        <select
          className="p-2 rounded bg-gray-600 text-white font-bold shadow-inner shadow-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600"
          value={selectedInstance}
          onChange={handleInstanceChange}
        >
          {instances.map((instance) => (
            <option key={instance} value={instance}>
              {instance}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="p-3 rounded bg-gray-400 text-white font-bold placeholder-gray-300 shadow-inner shadow-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600"
          style={{ width: '500px' }}
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Add a new task"
        />
        <button
          onClick={addTask}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded"
        >
          +
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <div className="flex justify-center items-start space-x-5 px-10 overflow-x-auto" style={{ maxWidth: '100vw' }}>
          {Object.entries(columns).map(([columnId, column], index) => (
            <div key={columnId} className="flex-shrink-0" style={{ width: '300px' }}>
              <div
                className={`p-2 rounded-lg shadow-lg shadow-black ${
                  index === 0 ? 'bg-red-800' : index === 1 ? 'bg-orange-800' : 'bg-green-800'
                }`}
                style={{ minHeight: '144px' }} // Ensure the colored container has a minimum height
              >
                <div className="flex justify-center items-center mb-4">
                  {editingColumnId === columnId ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="text-xl text-white font-bold text-center bg-transparent focus:outline-none"
                        value={editedColumnName}
                        onChange={handleColumnNameChange}
                        onBlur={() => setEditingColumnId(null)}
                      />
                      {editingColumnId === columnId && (
                        <button
                          className="ml-2 bg-green-700 hover:bg-green-800 text-white font-bold py-1 px-3 rounded"
                          onClick={() => handleColumnNameSave(columnId)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  ) : (
                    <h2
                      className="text-xl text-white font-bold text-center flex items-center cursor-pointer"
                      onClick={() => handleColumnNameEdit(columnId)}
                    >
                      {column.name}
                      <FaPencilAlt
                        className="ml-2 text-gray-400 cursor-pointer text-sm"
                        onClick={() => handleColumnNameEdit(columnId)}
                      />
                    </h2>
                  )}
                </div>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`bg-gray-700 p-2 rounded-lg shadow-inner shadow-gray-800 ${
                        snapshot.isDraggingOver ? 'bg-gray-600' : 'bg-gray-700'
                      }`}
                      style={{
                        minHeight: '144px', // 2x the standard card height
                        maxHeight: '500px', // Set max column height
                        overflowY: 'auto',
                        overflowX: 'hidden', // Ensures no horizontal scroll
                        position: 'relative',
                      }}
                    >
                      {column.items.map((item, index) => (
                        <MemoizedDraggableItem
                          key={item.id}
                          item={item}
                          index={index}
                          handleCardClick={handleCardClick}
                          handleDeleteCard={handleDeleteCard}
                          cardDetails={cardDetails}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
      {selectedCard && (
        <div
          className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleOutsideClick}
          style={{ zIndex: 9999 }}
        >
          <div className="bg-white rounded-lg p-8 w-1/2 max-w-lg relative">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
              onClick={handleCardClose}
            >
              <FaTimes size={20} />
            </button>
            <h2 className="text-2xl font-bold mb-4">{selectedCard.content}</h2>
            <textarea
              ref={detailsInputRef}
              className="w-full h-32 p-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-gray-400 bg-gray-300 shadow-inner shadow-gray-400"
              value={cardDetails[selectedCard.id] || ''}
              onChange={handleDetailsChange}
              onBlur={handleDetailsBlur}
              onKeyPress={handleDetailsKeyPress}
              placeholder="Additional information about the card..."
            />
            <div className="mt-4 flex items-center space-x-4">
              <span className="text-gray-700 font-bold">Priority:</span>
              <select
                className="p-2 rounded bg-gray-600 text-white shadow-inner shadow-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600"
                value={cardPriority}
                onChange={handlePriorityChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToDoList;
