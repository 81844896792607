// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Calculator from './calculator';
import ToDoList from './ToDoList'; // Ensure ToDoList is imported correctly

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-700">
        <nav className="bg-gray-900 w-full py-4 fixed top-0 z-10">
          <ul className="flex justify-center">
            <li className="mx-4">
              <Link className="text-white" to="/calculator">Calculator</Link>
            </li>
            <li className="mx-4">
              <Link className="text-white" to="/link1">To Do List</Link>
            </li>
            <li className="mx-4">
              <Link className="text-white" to="/link2">Other Link</Link>
            </li>
          </ul>
        </nav>
        <div className="flex flex-col items-center justify-center pt-20">
          <Routes>
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/Link1" element={<ToDoList />} /> {/* Ensure ToDoList is rendered here */}
            <Route path="/Link2" element={<div className="text-white">Placeholder for Link 2</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
